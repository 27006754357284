// this imports the same libraries that used to be loaded by requireJS
// next step is to conditionally import modules actually used on a page
//
import 'jquery';
import 'jquery-ui';
import 'magnific-popup';
import 'fg-ajax-include';
import 'jquery-ajax-unobtrusive';
import 'jquery-lazyload';
import 'jquery-throttle-debounce';
import 'pubsub';
import 'lib/terabyte/jquery.lazyscroll';
import 'lib/terabyte/jquery.tabbery';
import 'lib/terabyte/jquery.placeholdit';
import 'templayed';
import 'jquery-match-height';
import 'selectize';
import 'lib/sfDatePicker';

// this makes it globally available
// next step: only import this where it is actually used
import { io } from "socket.io-client";
window.io = io;